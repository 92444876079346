import { hex2rgb } from '@elo-ui/utils/colors.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

import { COLORS } from '@elo-kit/constants/general.constants'

const { eloBlack, eloGrey } = COLORS

const funnelProductStyles = (theme) => ({
  productContainer: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    '& .elo-product-display__name': {
      fontFamily: cleanupFonts(theme.productFont) || 'Montserrat Medium',
      fontSize: `${theme.productSize || 20}px`,
      color: theme.productColor || eloBlack,
      ...getFontStyles(theme.productStyle || 'bold'),
    },

    '& .elo-avatar': {
      borderRadius: `${theme.borderRadius || 0}px`,
    },

    '& .elo_price_display__pricing span.elo_price_display__price': {
      fontFamily: cleanupFonts(theme.priceFont) || 'Montserrat Medium',
      fontSize: `${theme.priceSize || 24}px`,
      color: theme.priceColor || eloBlack,
      ...getFontStyles(theme.priceStyle || 'bold'),
    },

    '& .elo_price_display span.elo_price_display__supportive_text': {
      fontFamily: cleanupFonts(theme.priceFormFont) || 'Montserrat Medium',
      fontSize: `${theme.priceFormSize || 12}px`,
      color: theme.priceFormColor || eloGrey,
      ...getFontStyles(theme.priceFormStyle || 'normal'),
    },

    '& div.elo-product-display__internal-name': {
      fontFamily: cleanupFonts(theme.productDescriptionFont) || 'Montserrat Medium',
      fontSize: `${theme.productDescriptionSize || 14}px`,
      color: theme.productDescriptionColor || eloGrey,
      ...getFontStyles(theme.productDescriptionStyle || 'normal'),
    },
  },
  productBackground: {
    backgroundColor:
      !theme.backgroundTransparency || theme.backgroundTransparency === 0
        ? 'transparent'
        : hex2rgb(theme.backgroundColor, theme.backgroundTransparency),
    position: 'absolute',
    inset: '0px',
  },
})

export default funnelProductStyles
