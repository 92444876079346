import React from 'react'

import { withStores } from 'utils/helpers.utils'
import { getFormattedTicketLocation } from 'utils/tickets.utils'
import { getSearchParams } from 'utils/queryString.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { formatMoney } from 'utils/money.utils'
import { audioPlayer } from 'utils/audio.utils'

import BlockProduct from '@elo-kit/components/page-builder/blocks/product/block-product'

const ProductPreviewBlock = (props) => {
  const {
    trackingCodesStore,
    contentPageStore,
    currenciesStore,
    previewType,
    previewMode,
    historyPush,
    LinkComponent,
    countries,
    block,
  } = props
  const {
    seller,
    product,
    lessons,
    protectProductByPass,
    productProtectedByPass,
    isAppActive,
    courseThemesStore,
    productLoading,
    toggleLoading,
  } = contentPageStore
  const { getUnit, getCurrencySignPosition } = currenciesStore
  const webProtocol = getElopageConfig('webProtocol')

  return (
    <BlockProduct
      {...props}
      getFormattedTicketLocation={getFormattedTicketLocation}
      historyPush={historyPush}
      courseItem={courseThemesStore.item || {}}
      getByKey={() => getSearchParams()}
      errorTitleMessage={I18n.t('react.shared.error.title')} // delete this after update ui kit
      webProtocol={webProtocol}
      fetchCourse={courseThemesStore.fetchItem}
      convertToPrice={(value) => formatMoney(value ?? 0, 2, ',', '.')} // change to convertToPriceWithoutPrice after update ui kit
      {...{
        getUnit,
        seller,
        product,
        previewType,
        previewMode,
        countries,
        lessons,
        protectProductByPass,
        productProtectedByPass,
        isAppActive,
        productLoading,
        toggleLoading,
        blockId: block.id,
        getUnitPosition: getCurrencySignPosition,
      }}
      LinkComponent={LinkComponent}
      AudioPlayer={audioPlayer}
      forceFireTracify={trackingCodesStore.forceFireTracify}
    />
  )
}

const stores = ['contentPageStore', 'currenciesStore', 'trackingCodesStore']
export default withStores(stores, ProductPreviewBlock)
