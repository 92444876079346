export const MIN_TICKETS_COUNT = 1
export const TWO_WEEKS = 6.04e8 * 2
export const ONE_DAY = 8.64e7

export const INVOICE_STATES = {
  paid: 'paid',
  unpaid: 'unpaid',
  canceled: 'canceled',
}
export const INVOICE_STATES_VALUES = Object.values(INVOICE_STATES)
export const MONTHLY_INVOICES_UNPAID_STATES = ['unpaid', 'refunded', 'chargebacked']
export const INVOICE_FILTER_LIST = ['invoiceState', 'invoiceForm', 'orderId', 'paymentForm', 'issuedAt']

export const SELLER_INVOICES_CSV_PARAMS = [
  {
    label: I18n.t('react.cabinet.common.id'),
    csvKey: 'id',
  },
  {
    label: I18n.t('react.cabinet.common.number'),
    csvKey: 'number',
  },
  {
    label: I18n.t('react.cabinet.common.payer_full_name'),
    csvKey: 'buyer_name',
  },
  {
    label: I18n.t('react.cabinet.common.payer_email'),
    csvKey: 'buyer_email',
  },
  {
    label: I18n.t('react.cabinet.common.order_id'),
    csvKey: 'order_id',
  },
  {
    label: I18n.t('react.cabinet.common.product_id'),
    csvKey: 'product_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_name'),
    csvKey: 'product_name',
  },
  {
    label: I18n.t('react.cabinet.common.state'),
    csvKey: 'state',
  },
  {
    label: I18n.t('react.common.transfer_id'),
    csvKey: 'transfer_ids',
  },
  {
    label: I18n.t('react.common.method'),
    csvKey: 'order_payment_form',
  },
  {
    label: I18n.t('react.cabinet.common.created'),
    csvKey: 'created_at',
  },
  {
    label: I18n.t('react.cabinet.common.deadline'),
    csvKey: 'invoice_deadline',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.additional_address'),
    csvKey: 'buyer_additional_address',
  },
  {
    label: I18n.t('react.cabinet.common.type'),
    csvKey: 'form',
  },
  {
    label: I18n.t('react.cabinet.common.cost_center'),
    csvKey: 'cost_center',
  },
  {
    label: I18n.t('react.common.payment_type'),
    csvKey: 'order_period_type',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_vat_no'),
    csvKey: 'buyer_vat_no',
  },
  {
    label: I18n.t('react.shared.transfers.csv.currency'),
    csvKey: 'order_currency_key',
  },
  {
    label: I18n.t('react.shared.transfers.csv.order_upsell_type'),
    csvKey: 'order_upsell_type',
  },
  {
    label: I18n.t('react.shared.transfers.csv.invoice_amount'),
    csvKey: 'invoice_amount',
  },
  {
    label: I18n.t('react.shared.transfers.csv.vat_rate'),
    csvKey: 'vat_rate',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_gross'),
    csvKey: 'product_gross',
  },
  {
    label: I18n.t('react.shared.transfers.csv.performance_period'),
    csvKey: 'performance_period',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.additional_fee'),
    csvKey: 'additional_fee',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.discount'),
    csvKey: 'discount',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.child_product_id'),
    csvKey: 'child_product_id',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.child_product_name'),
    csvKey: 'child_product_name',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.psp'),
    csvKey: 'provider',
  },
  {
    label: I18n.t('react.cabinet.common.issued'),
    csvKey: 'issued_at',
  },
]

export const SELLER_INVOICES_TAX_CSV_PARAMS = [
  {
    label: I18n.t('react.shared.transfers.tax.product_gross'),
    csvKey: 'product_gross',
  },
  {
    label: I18n.t('react.shared.transfers.tax.debit'),
    csvKey: 'empty',
  },
  {
    label: I18n.t('react.shared.transfers.tax.currency'),
    csvKey: 'order_currency_key',
  },
  {
    label: I18n.t('react.shared.account'),
    csvKey: 'empty',
  },
  {
    label: I18n.t('react.shared.transfers.tax.contra_account'),
    csvKey: 'empty',
  },
  {
    label: I18n.t('react.shared.transfers.tax.bu_schluessel'),
    csvKey: 'empty',
  },
  {
    label: I18n.t('react.shared.transfers.tax.invoice_date'),
    csvKey: 'invoice_date',
  },
  {
    label: I18n.t('react.shared.transfers.tax.performance_period'),
    csvKey: 'empty',
  },
  {
    label: I18n.t('react.shared.transfers.tax.order_id'),
    csvKey: 'order_id',
  },
  {
    label: I18n.t('react.shared.transfers.tax.deadline'),
    csvKey: 'invoice_deadline',
  },
  {
    label: I18n.t('react.shared.transfers.tax.cash_discount'),
    csvKey: 'empty',
  },
  {
    label: I18n.t('react.shared.transfers.tax.invoice_text'),
    csvKey: 'invoice_text',
  },
  {
    label: I18n.t('react.shared.transfers.tax.cost_center'),
    csvKey: 'empty',
  },
  {
    label: I18n.t('react.shared.transfers.tax.country_code'),
    csvKey: 'buyer_country_code',
  },
  {
    label: I18n.t('react.shared.transfers.tax.buyer_vat_no'),
    csvKey: 'buyer_vat_no',
  },
  {
    label: I18n.t('react.shared.transfers.tax.vat_rate'),
    csvKey: 'vat_rate',
  },
  {
    label: I18n.t('react.shared.transfers.tax.order_provider'),
    csvKey: 'order_provider',
  },
  {
    label: I18n.t('react.shared.transfers.tax.order_payment_form'),
    csvKey: 'order_payment_form',
  },
  {
    label: I18n.t('react.shared.transfers.tax.payer_first_name'),
    csvKey: 'buyer_first_name',
  },
  {
    label: I18n.t('react.shared.transfers.tax.payer_last_name'),
    csvKey: 'buyer_last_name',
  },
  {
    label: I18n.t('react.shared.transfers.tax.invoice_label'),
    csvKey: 'invoice_label',
  },
  {
    label: I18n.t('react.shared.transfers.tax.invoice_id'),
    csvKey: 'invoice_id',
  },
  {
    label: I18n.t('react.shared.transfers.tax.allocation'),
    csvKey: 'empty',
  },
  {
    label: I18n.t('react.shared.transfers.tax.product_net_amount'),
    csvKey: 'product_net',
  },
  {
    label: I18n.t('react.shared.transfers.tax.product_vat_amount'),
    csvKey: 'product_vat_amount',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.psp'),
    csvKey: 'provider',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.buyer_state'),
    csvKey: 'buyer_state',
  },
  {
    label: I18n.t('react.cabinet.common.number'),
    csvKey: 'number',
  },
  {
    label: I18n.t('react.cabinet.common.product_id'),
    csvKey: 'product_id',
  },
  {
    label: I18n.t('react.cabinet.common.state'),
    csvKey: 'state',
  },
  {
    label: I18n.t('react.common.transfer_id'),
    csvKey: 'transfer_ids',
  },
  {
    label: I18n.t('react.cabinet.common.type'),
    csvKey: 'form',
  },
  {
    label: I18n.t('react.common.payment_type'),
    csvKey: 'order_period_type',
  },
  {
    label: I18n.t('react.shared.transfers.csv.order_upsell_type'),
    csvKey: 'order_upsell_type',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.discount'),
    csvKey: 'discount',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.child_product_id'),
    csvKey: 'child_product_id',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.child_product_name'),
    csvKey: 'child_product_name',
  },
  {
    label: I18n.t('react.cabinet.common.issued'),
    csvKey: 'issued_at',
  },
]

export const SELLER_INVOICES_TAX_V2_CSV_PARAMS = [
  {
    label: I18n.t('react.shared.transfers.tax.additional_fee'),
    csvKey: 'additional_fee',
  },
  {
    label: I18n.t('react.shared.transfers.tax.discount'),
    csvKey: 'discount',
  },
]
